import { CONSTANTS, NFetch, SERVER } from '../api';

export const DashboardAction = {
    getLatestEntry: getLatestEntry
};

async function getLatestEntry(dispatch, count = 10) {
    try {
        const result = await NFetch.GetQuery(SERVER.API.Dashboard.LatestEntry, { count: count })
        dispatch({ type: CONSTANTS.DASHBOARD.DATA, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}