import { CONSTANTS } from '../api/constants';
let initState = {
  error: null,
  message: "",
  data: [],
  selectedId: 0,
  dataList: [],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1
  },
  searchValue: '',
  dataSearch: [],
  count: {
    activeDoor: 0,
    activeAlert: 0,
    openedDoor: 0,
  }
}
export function accessgroup(state = initState, action) {
  let __state = {}
  switch (action.type) {
    case CONSTANTS.ACCESSGROUP.LIST_FULFILLED:
      let _data = []

      // console.log(action.payload)

      for (let i = 0; i < action.payload.length; i++) {

        if (i < 10) {
          _data.push(action.payload[i])
        }
      }

      __state = {
        ...state,
        data: action.payload,
        dataList: _data,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(action.payload.length / state.pagination.itemPerPage)
        },
      };

      break;
    case CONSTANTS.ACCESSGROUP.SELECTED:
      __state = {
        ...state,
        selectedId: action.payload
      };
      break;
    case CONSTANTS.ACCESSGROUP.COUNT_CHANGE:
      __state = { ...state }
      __state.pagination.itemPerPage = action.payload
      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.data.length / action.payload)

      __state.dataList = []
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.payload) {
          __state.dataList.push(__state.data[i])
        }
      }
      break;
    case CONSTANTS.ACCESSGROUP.PAGE_CHANGE:
      __state = { ...state }
      __state.pagination.currentPage = action.payload

      __state.dataList = []
      let _j = 0
      for (let i = ((action.payload - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i])
          _j++
        }
      }
      break;

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state;
  }
  return __state;
}