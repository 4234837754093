import { CONSTANTS } from '../api/constants';
import Dates from '../__ifunc/dates'
let initState = {
    error: null,
    message: "",
    data: [],
    dataList: [],
    pagination: {
        itemPerPage: 10,
        currentPage: 1,
        totalPage: 1
    },
    count: {
        overall: 0,
        totalIn: 0,
        totalOut: 0
    },
    todayList: [],
    searchValue: '',
    dataSearch: [],
}
export function logs(state = initState, action) {
    let __state = {}
    switch (action.type) {
        case CONSTANTS.LOGS.LIST_FULFILLED:
            let _data = []
            // console.log("action payload", action.payload)
            for (let i = 0; i < action.payload.length; i++) {
                action.payload[i].no = i + 1
                action.payload[i].timestamp = Dates.format(action.payload[i].timestamp, Dates.FORMAT.DATE_TIME5)

                if (i < 10) {
                    _data.push(action.payload[i])
                }
            }

            state = {
                ...state,
                data: action.payload,
                dataList: _data,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.payload.length / state.pagination.itemPerPage)
                },
                error: null
            };
            break;
        case CONSTANTS.LOGS.LIST_REJECTED:
            state = {
                ...state,
                error: null
            };
            break;
        case CONSTANTS.LOGS.COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.itemPerPage = action.payload
            __state.pagination.currentPage = 1
            __state.pagination.totalPage = Math.ceil(__state.data.length / action.payload)

            __state.dataList = []
            for (let i = 0; i < __state.data.length; i++) {
                if (i < action.payload) {
                    __state.dataList.push(__state.data[i])
                }
            }

            state = {
                ...__state
            };
            break;
        case CONSTANTS.LOGS.PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.payload

            __state.dataList = []
            let _j = 0
            for (let i = ((action.payload - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
                if (_j < state.pagination.itemPerPage) {
                    __state.dataList.push(__state.data[i])
                    _j++
                }
            }

            state = {
                ...__state
            };
            break;
        case CONSTANTS.LOGS.COUNT_FULFILLED:
            __state = { ...state }

            __state.count = action.payload
            state = {
                ...__state
            };
            break;
        case CONSTANTS.LOGS.TODAY_LIST_FULFILLED:
            __state = { ...state }

            __state.todayList = action.payload
            state = {
                ...__state
            };
            break;
        case CONSTANTS.LOGS.SEARCH_BY_NAME:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []

            __state.pagination.currentPage = 1
            __state.pagination.searchValue = action.payload

            state.data.forEach(element => {
                if (element.names.includes(action.payload)) {
                    __state.dataSearch.push(element)
                }
            });

            __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

            _j = 0
            for (let i = 0; i < __state.dataSearch.length; i++) {
                if (_j < state.pagination.itemPerPage) {
                    __state.dataList.push(__state.dataSearch[i])
                    _j++
                }
            }

            state = {
                ...__state
            };

            break;
        case CONSTANTS.LOGS.SEARCH_BY_LOCATION:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []

            __state.pagination.currentPage = 1
            __state.pagination.searchValue = action.payload

            state.data.forEach(element => {
                if (element.door.includes(action.payload)) {
                    __state.dataSearch.push(element)
                }
            });

            __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

            _j = 0
            for (let i = 0; i < __state.dataSearch.length; i++) {
                if (_j < state.pagination.itemPerPage) {
                    __state.dataList.push(__state.dataSearch[i])
                    _j++
                }
            }

            state = {
                ...__state
            };

            break;

        case CONSTANTS.CLEAR:
            return initState
        default:
            return state;
    }
    return state;
}