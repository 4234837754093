import { CONSTANTS, NFetch, SERVER } from '../api'
export const AccessGroupAction = {
    register: register,
    deletes: deletes,
    getList: getList,
    modifyNames: modifyNames,
    addDoorToGroup,
    removeDoorFromGroup
}
function register(data) {
    return NFetch.Post(SERVER.API.AccessGroup.Register, data)
}
function modifyNames(id, names) {
    return NFetch.Post(SERVER.API.AccessGroup.ModifyNames(id), { names: names })
}
function deletes(id) {
    return NFetch.Post(SERVER.API.AccessGroup.Removed(id))
}
async function getList(dispatch) {
    try {
        const result = await NFetch.GetQuery(SERVER.API.AccessGroup.Lists)
        dispatch({ type: CONSTANTS.ACCESSGROUP.LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}
function addDoorToGroup(groupId, data){
    return NFetch.Post(SERVER.API.AccessGroup.AddDoor(groupId), data)
}
function removeDoorFromGroup(groupId, doorId){
    return NFetch.Post(SERVER.API.AccessGroup.RemovedDoor(groupId, doorId))
}