import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'reactstrap';
import PageFirst from './PageFirst/PageFirst';
import PagePrev from './PagePrev/PagePrev';
import PageItems from './PageItems/PageItems';
import PageNext from './PageNext/PageNext';
import PageLast from './PageLast/PageLast';

// const TablePagination = (
//     {
//     pagination,
//     sheetsPerPage,
//     dispatch,
//     types,
//     onSelected
// }

// ) => {
//     return (
//         <div style={{ display: 'inline-block' }}>
//             <Pagination size="sm" aria-label="Page navigation" className="table-pgn">
//                 <PageFirst
//                     pagination={pagination}
//                     dispatch={dispatch}
//                     types={types}
//                     onSelected={onSelected}
//                 />
//                 <PagePrev
//                     pagination={pagination}
//                     pgsNo={sheetsPerPage}
//                     dispatch={dispatch}
//                     types={types}
//                     onSelected={onSelected}
//                 />
//                 <PageItems
//                     pagination={pagination}
//                     pgsNo={sheetsPerPage}
//                     dispatch={dispatch}
//                     types={types}
//                     onSelected={onSelected}
//                 />
//                 <PageNext
//                     pagination={pagination}
//                     pgsNo={sheetsPerPage}
//                     dispatch={dispatch}
//                     types={types}
//                     onSelected={onSelected}
//                 />
//                 <PageLast
//                     pagination={pagination}
//                     dispatch={dispatch}
//                     types={types}
//                     onSelected={onSelected}
//                 />
//             </Pagination>
//         </div>
//     )
// };

const TablePagination = (prop) => {
    return (
        <div style={{ display: 'inline-block' }}>
            <Pagination size="sm" aria-label="Page navigation" className="table-pgn">
                <PageFirst {...prop} />
                <PagePrev {...prop} />
                <PageItems {...prop} />
                <PageNext {...prop} />
                <PageLast {...prop} />
            </Pagination>
        </div>
    )
}

TablePagination.propTypes = {
    pagination: PropTypes.object,
    sheetsPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default TablePagination;
