import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

function PageItems({ pagination, sheetsPerPage, dispatch, types, onSelected }) {
    let pageCurrentSection = Math.ceil(pagination.currentPage / sheetsPerPage);
    let pageCurrentMax = sheetsPerPage;
    if (pagination.totalPage < sheetsPerPage) {
        pageCurrentMax = pagination.totalPage;
    } else {
        if (pageCurrentSection * sheetsPerPage > pagination.totalPage) {
            pageCurrentMax = sheetsPerPage - (pageCurrentSection * sheetsPerPage - pagination.totalPage);
        }
    }
    let pageCount = Array.from(
        Array(pageCurrentMax),
        (_, x) => (pageCurrentSection - 1) * sheetsPerPage + 1 + x
    );
    return pageCount.map(page => {
        let active = true;
        if (page !== pagination.currentPage) {
            active = false;
        }
        return (
            <PaginationItem key={page} active={active}>
                <PaginationLink
                    onClick={() => {
                        if (dispatch && types)
                            // dispatch(action(page));
                            dispatch({ type: types, payload: page })
                            //  dispatch({ type: "LOGS_PAGE_CHANGE", payload: type })
                        else
                            onSelected(page)
                    }}
                >
                    {page}
                </PaginationLink>
            </PaginationItem>
        );
    });
}

PageItems.propTypes = {
    pagination: PropTypes.object,
    sheetsPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PageItems;

