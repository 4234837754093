import React from 'react';
import PropTypes from 'prop-types';
import { PaginationItem, PaginationLink } from 'reactstrap';

const PagePrev = ({ pagination, sheetsPerPage, dispatch, types, onSelected }) => {
    let pageCurrentSection = Math.ceil(pagination.currentPage / sheetsPerPage);
    if (pageCurrentSection === 1) {
        return (
            <PaginationItem disabled>
                <PaginationLink previous />
            </PaginationItem>
        );
    }
    return (
        <PaginationItem>
            <PaginationLink
                previous
                onClick={() => {
                    if (dispatch && types) {
                        // dispatch(action((pageCurrentSection - 2) * pgsNo + pgsNo));
                        dispatch({ type: types, payload: (pageCurrentSection - 2) * sheetsPerPage + sheetsPerPage })
                        // dispatch({ type: "LOGS_PAGE_CHANGE", payload: pagination.currentPage - 1 })
                    }
                    else {
                        onSelected((pageCurrentSection - 2) * sheetsPerPage + sheetsPerPage);
                    }
                }}
            />
        </PaginationItem>
    );
};

PagePrev.propTypes = {
    pagination: PropTypes.object,
    sheetsPerPage: PropTypes.number,
    dispatch: PropTypes.func,
    types: PropTypes.string,
    onSelected: PropTypes.func
};

export default PagePrev;
