import { MqttC, WebSocketClient, WebSocketIOClient } from '../__ifunc'
import alertify from 'alertifyjs'

import { DashboardAction, DoorAction, UserAction } from '../__actions'
import { CONSTANTS } from './constants'
import store from '../store'

const MQTT = 1
const WEBSOCKET = 2
const SOCKETIO = 3

// Select Mode
const mode = MQTT
// const mode = WEBSOCKET
// const mode = SOCKETIO

class RTApi {
    start() {
        if (mode === MQTT) {
            this.instance = new MqttC()
            this.instance.start(receiveMessage)
        } else if (mode === WEBSOCKET) {
            this.instance = new WebSocketClient(receiveMessage)
            this.instance.open()
        } else if (mode === SOCKETIO) {
            this.instance = new WebSocketIOClient(receiveMessage)
        }
    }
    stop() {
        if (this.instance !== null) {
            this.instance.close()
        }
    }
    close() {
        if (this.instance !== null) {
            this.instance.close()
        }
    }
}
export default RTApi

function receiveMessage(topic, message) {
    // console.log(topic, message)
    // const state = store.getState()
    if (topic === 'AXSES/LIVE/UPDATE') {
        DashboardAction.getLatestEntry(store.dispatch)
    } else if (topic === 'AXSES/ALERT/TRIGGER') {
        // DoorAction.summaryInfo(store.dispatch)
        store.dispatch({ type: CONSTANTS.DOORS.TRIGGER, payload: message });
        alertify.error('Alarm Door ' + message.location + ' is Trigger!.')
    } else if (topic === 'AXSES/ALERT/DISMISS') {
        store.dispatch({ type: CONSTANTS.DOORS.DISMISS, payload: message });
        alertify.success('Alarm Door ' + message.location + ' is Dismiss!.')
    } else if (topic === 'AXSES/UPDATE/USERS') {
        UserAction.countProfile(store.dispatch)
    } else if (topic === 'AXSES/UPDATE/DOORS') {
        DoorAction.summaryInfo(store.dispatch)
    } else if (topic === 'AXSES/DEVICE/OFFLINE') {
        store.dispatch({ type: CONSTANTS.DOORS.OFFLINE, payload: message });
        alertify.error('Door ' + message.door.location + ' is Offline!.')
    } else if (topic === 'AXSES/DEVICE/ONLINE') {
        store.dispatch({ type: CONSTANTS.DOORS.ONLINE, payload: message });
        // alertify.error('Door ' + message.door.location + ' is Offline!.')
    } else if (topic === 'MESSAGE') {
        if (message.status) {
            alertify.success(message.msg)
        } else {
            alertify.error(message.msg)
        }
    }
}