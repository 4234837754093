import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { doors } from './doors'
import { logs } from './logs'
import { users } from './users'
import { organization } from './organization'
import { accessgroup } from './accessgroup'

const rootReducer = combineReducers({
  dashboard,
  doors,
  logs,
  users,
  organization,
  accessgroup,
})

export default rootReducer