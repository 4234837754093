
import { SERVER } from '../api'
const io = require('socket.io-client')

class WebSocketIOClient {
    constructor(cb) {
        this.cb = cb;

        this.socket = io(SERVER.RTAPI.URL);
        this.socket.on('connect', () => {
            console.log("Connected..")
            // either with send()
            // this.socket.send('Hello! from browser.');
            // or with emit() and custom event names
            // socket.emit('salutations', 'Hello!', { 'mr': 'john' }, Uint8Array.from([1, 2, 3, 4]));
        });

        // handle the event sent with socket.send()
        this.socket.on('message', data => {
            this.cb(data.topic, data.message)
        });
        this.socket.on('notification', function (message) {
            // console.log('notification', message);
            this.cb('notification', message)
        });
    }

    send(data) {
        try {
            this.socket.send(data);
        } catch (e) {
            console.log(e)
        }
    }
    close() {
        this.socket.disconnect()
    }
}
export default WebSocketIOClient
