import { CONSTANTS } from '../api/constants'
import Dates from '../__ifunc/dates'

let initState = {
    status: '',
    error: null,
    log: [],
    totalIn: {
        total: 0,
        doors: [],
        data: []
    },
    totalOut: {
        total: 0,
        doors: [],
        data: []
    }
}

export function dashboard(state = initState, action) {
    //console.log(action)
    // let __state = { ...state }
    switch (action.type) {
        case CONSTANTS.DASHBOARD.DATA:
            // console.log(action.payload)
            if(typeof action.payload.log === 'undefined'){
                return state
            }
            for (let i = 0; i < action.payload.log.length; i++) {
                action.payload.log[i].timestamp = Dates.format(action.payload.log[i].timestamp, Dates.FORMAT.DATE_TIME5)
            }

            state = {
                ...state, ...action.payload
            }
            return state
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}
