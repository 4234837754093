import { CONSTANTS, NFetch, SERVER } from '../api';

export const UserAction = {
    register: register,
    deletes: deletes,
    deleteProfile: deleteProfile,
    getListUser,
    removedAdminUser,
    updateId,
    updateName,
    updateEmail,
    updateModes,
    updateUserRoleMode,

    countProfile: countProfile,
    getUserRfidCard: getUserRfidCard,
    getUserPasswordPin: getUserPasswordPin,
    getUserFingerPrint: getUserFingerPrint,
    getUserFingerPrintCount: getUserFingerPrintCount,
    getUserFace: getUserFace,
    downloadUserToDoor: downloadUserToDoor,
    updateUserInfo: updateUserInfo,
    getUserAccessInfo: getUserAccessInfo,
    updateUserAccessInfo: updateUserAccessInfo,
    updateOrganization,

    getListDoors: getListDoors,
    updateUserAccessTime: updateUserAccessTime,
    updateUserAccessDate: updateUserAccessDate,

    addGroupToUser,
    removeGroupFromUser
}

function register(data) {
    return NFetch.Post(SERVER.API.ProfileUsers.Register, data)
}
function deletes(userId) {
    return NFetch.Post(SERVER.API.AppUser.Removed, { id: userId })
}
function deleteProfile(userId) {
    return NFetch.Delete(SERVER.API.ProfileUsers.Removed(userId))
}
function removedAdminUser(userId) {
    return NFetch.Post(SERVER.API.AppUser.Removed, { id: userId })
}
async function getListUser(dispatch) {
    try {
        let admin = getListAdmin().catch(error => { return [] })
        let staff = getListStaff().catch(error => { return [] })

        const result = await Promise.all([admin, staff])
        dispatch({ type: CONSTANTS.USER.LIST_FULFILLED, payload: { adminList: result[0], staffList: result[1] } });
    } catch (error) {
        return Promise.reject(error)
    }
}

function getListAdmin() {
    return NFetch.GetQuery(SERVER.API.AppUser.List)
}
function getListStaff() {
    return NFetch.GetQuery(SERVER.API.ProfileUsers.List)
}

async function countProfile(dispatch) {
    try {
        const result = await NFetch.GetQuery(SERVER.API.ProfileUsers.Count)
        dispatch({ type: CONSTANTS.USER.COUNT_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}

function updateUserInfo(id, data) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserInfo(id), data)
}

function getUserRfidCard(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.UserRfidCard(profileUserId))
}
function getUserPasswordPin(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.UserPasswordPin(profileUserId))
}
function getUserFingerPrint(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.UserFingerPrint(profileUserId))
}

export function getUserFingerPrintCount(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.UserFingerPrintCount(profileUserId))
}
export function getUserFace(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.UserFace(profileUserId))
}

export function downloadUserToDoor(doorid, listId) {
    // return IRequest.Post(SERVER.API.ProfileUsers.DownloadToDoor(doorid), { user: listId })
    return NFetch.Post(SERVER.API.Doors.DownloadToDoor(doorid), { user: listId })
}

function getUserAccessInfo(profileUserId) {
    return NFetch.GetWithFilter(SERVER.API.ProfileUsers.UserAccessInfo, { where: { profileUserId: profileUserId } })
}
function updateUserAccessInfo(profileUserId, data) {
    return NFetch.Patch(SERVER.API.ProfileUsers.UpdateUserAccessInfo(profileUserId), data)
}

function getListDoors(profileUserId) {
    return NFetch.Get(SERVER.API.ProfileUsers.ListDoors(profileUserId))
}
function updateUserAccessTime(profileUserId, doorId, accessTime) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserAccessTime(profileUserId, doorId), { accessTime: accessTime })
}
function updateUserAccessDate(profileUserId, doorId, accessDate) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserAccessDate(profileUserId, doorId), { accessDate: accessDate })
}

async function updateUserRoleMode(profileUserId, modes, dispatch) {
    try {
        await updateModes(profileUserId, modes)
        dispatch({ type: CONSTANTS.USER.STAFF_UPDATE_INFO, payload: { id: profileUserId, admin: modes } })
        return Promise.resolve('Complete.')
    } catch (error) {
        return Promise.reject(error)
    }
}

function updateModes(profileUserId, modes) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserModes(profileUserId), { modes: modes })
}
function updateOrganization(profileUserId, organizationtId) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserOrganization(profileUserId, organizationtId), {})
}

function addGroupToUser(profileUserId, data) {
    return NFetch.Post(SERVER.API.ProfileUsers.AddGroup(profileUserId), data)
}
function removeGroupFromUser(profileUserId, groupId) {
    return NFetch.Post(SERVER.API.ProfileUsers.RemovedGroup(profileUserId, groupId))
}

function updateId(profileUserId, id) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserId(profileUserId), { id: id })
}
function updateName(profileUserId, name) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserName(profileUserId), { name: name })
}
function updateEmail(profileUserId, email) {
    return NFetch.Post(SERVER.API.ProfileUsers.UpdateUserEmail(profileUserId), { email: email })
}
