import { CONSTANTS, NFetch, SERVER } from '../api'
export const OrganizationAction = {
    register: register,
    deletes: deletes,
    getList: getList,
    modifyNames: modifyNames,
}
function register(data) {
    return NFetch.Post(SERVER.API.Organization.Register, data)
}
function modifyNames(id, names) {
    return NFetch.Post(SERVER.API.Organization.ModifyNames(id), { names: names })
}
function deletes(id) {
    return NFetch.Post(SERVER.API.Organization.Removed(id))
}
async function getList(dispatch) {
    try {
        const result = await NFetch.GetQuery(SERVER.API.Organization.MAIN)
        dispatch({ type: CONSTANTS.ORGANIZATION.LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}