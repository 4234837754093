// View Page
const DASHBOARD = {
  DATA: 'DASHBOARD_DATA',
}
const USER = {
  LIST_FULFILLED: 'USERS_LIST_FULFILLED',
  LIST_ADD: 'USERS_LIST_ADD',
  REGISTER_FULFILLED: 'USERS_REGISTER_FULFILLED',
  SELECTED: 'USERS_SELECTED',
  COUNT_CHANGE: 'USERS_COUNT_CHANGE',
  ADMIN_COUNT_CHANGE: 'USERS_ADMIN_COUNT_CHANGE',
  ADMIN_PAGE_CHANGE: 'USERS_ADMIN_PAGE_CHANGE',
  STAFF_COUNT_CHANGE: 'USERS_STAFF_COUNT_CHANGE',
  STAFF_PAGE_CHANGE: 'USERS_STAFF_PAGE_CHANGE',
  STAFF_SEARCH: 'USERS_STAFF_SEARCH',
  ADMIN_SEARCH: 'USERS_ADMIN_SEARCH',
  COUNT_FULFILLED: 'USERS_COUNT_FULFILLED',
  STAFF_UPDATE_INFO: 'USERS_STAFF_UPDATE_INFO',
  SELECTED_ADMIN: 'USERS_SELECTED_ADMIN',
  SELECTED_STAFF: 'USERS_SELECTED_STAFF',
}
const LOGS = {
  LIST_FULFILLED: 'LOGS_LIST_FULFILLED',
  LIST_REJECTED: 'LOGS_LIST_REJECTED',
  COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
  PAGE_CHANGE: 'LOGS_PAGE_CHANGE',
  COUNT_FULFILLED: 'LOGS_COUNT_FULFILLED',
  TODAY_LIST_FULFILLED: 'LOGS_TODAY_LIST_FULFILLED',
  SEARCH_BY_NAME: 'LOGS_SEARCH_BY_NAME',
  SEARCH_BY_LOCATION: 'LOGS_SEARCH_BY_LOCATION',
}
const DOORS = {
  LIST_FULFILLED: 'DOORS_LIST_FULFILLED',
  LIST_ADD: 'DOORS_LIST_ADD',
  LIST_REJECTED: 'DOORS_LIST_REJECTED',
  SELECTED: 'DOORS_SELECTED',
  COUNT_CHANGE: 'DOORS_COUNT_CHANGE',
  PAGE_CHANGE: 'DOORS_PAGE_CHANGE',
  SEARCH: 'DOOR_SEARCH',
  COUNT_FULFILLED: 'DOORS_COUNT_FULFILLED',
  UPDATE_INFO: 'DOORS_UPDATE_INFO',
  OFFLINE: 'DOORS_OFFLINE',
  ONLINE: 'DOORS_ONLINE',
  TRIGGER: 'DOORS_TRIGGER',
  DISMISS: 'DOORS_DISMISS'
}
const ORGANIZATION = {
  LIST_FULFILLED: 'ORGANIZATION_LIST_FULFILLED',
  COUNT_CHANGE: 'ORGANIZATION_COUNT_CHANGE',
  PAGE_CHANGE: 'ORGANIZATION_PAGE_CHANGE',
  SEARCH: 'ORGANIZATION_SEARCH',
  SELECTED: 'ORGANIZATION_SELECTED',
}
const ACCESSGROUP = {
  LIST_FULFILLED: 'ACCESSGROUP_LIST_FULFILLED',
  COUNT_CHANGE: 'ACCESSGROUP_COUNT_CHANGE',
  PAGE_CHANGE: 'ACCESSGROUP_PAGE_CHANGE',
  SEARCH: 'ACCESSGROUP_SEARCH',
  SELECTED: 'ACCESSGROUP_SELECTED',
}

// Others
const ROLE = {
  SYSTEM: 'system',
  ADMIN: 'admin',
  MANAGER: 'manager',
  SUPERVISOR: 'supervisor',
  NORMALUSER: 'normal'
}

const PATH = {
  LOGIN: '/login'
}

export const CONSTANTS = {
  CLEAR: 'CLEAR',
  TIME_IDLE: 30, // value in minute

  DASHBOARD,
  USER,
  LOGS,
  DOORS,
  ORGANIZATION,
  ACCESSGROUP,

  ROLE,
  PATH
}